






























































































































































import { useUserState, useUserActions } from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';

const { getStackholders, fetchAllOrganizations } = useUserActions([
  'getStackholders',
  'fetchAllOrganizations'
]);
const { addStackholders } = useUserActions(['addStackholders']);
const { removeStackholder } = useUserActions(['removeStackholder']);

export default defineComponent({
  name: 'ManageAccess',
  components: {
    ATeleInput: () => import('@/components/atoms/ATeleInput.vue')
  },
  props: {
    userType: {
      required: true,
      type: String,
      default: ''
    },
    programId: {
      required: true,
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      required: false
    }
  },
  setup(props, ctx: any) {
    const { user } = useUserState(['user']);
    const organizationType = ref('User');
    const manageOrg = ref(['User', 'Organization']);
    const manageInput = ref(['Email', 'Phone']);
    const manageAccess = ref(['Employer', 'Remove']);
    const organizationName = ref('');
    const organizationList = ref([]);
    const isPhoneValid = ref(false);
    const phoneNumber = ref('');
    const inviteType = ref('Email');
    const errorMsg = ref('');
    const orgMsg = ref('');
    const isSending = ref(false);
    const organizationData = ref();
    const newOrganizerEmail = ref('');
    const formattedNumber = ref('');
    const list: any = ref([]);
    const isFetching = ref(false);
    const isDeleting = ref(false);
    const deleteIndex = ref(-1);
    const isOrgExist = ref(false);

    const organizerList = async () => {
      list.value = [];
      isFetching.value = true;
      const result = await getStackholders({
        user_type: props.userType === 'organizer' ? 'Employer' : 'Student',
        program_id: props.programId,
        document_type: props?.isDraft ? 'ProgramRevision' : 'Program'
      });
      if (result) {
        list.value = result;
        isFetching.value = false;
      }
    };
    organizerList();

    const getAllOrganizations = async () => {
      isOrgExist.value = true;
      const organization = await fetchAllOrganizations();
      isOrgExist.value = false;
      organizationData.value = organization;
      organizationList.value = [];
      const organizationNames = organization.map(org => org.name);
      const name = organizationNames[0];
      organizationList.value = organizationNames;
      organizationName.value = name;
    };

    getAllOrganizations();

    const phoneValid = computed(() => {
      if (isPhoneValid.value && phoneNumber.value) {
        return true;
      }
      if (inviteType.value === 'Email') {
        return true;
      }
      return false;
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        formattedNumber.value = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        formattedNumber.value = '';
      }
      isPhoneValid.value = val.valid;
    }

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';
      if (phoneNumber.value.includes(substring) || phoneNumber.value.includes(substring1)) {
        return false;
      }
      if (inviteType.value === 'Email') {
        return true;
      }
      return true;
    });

    async function addOrganizer() {
      errorMsg.value = '';
      orgMsg.value = '';
      isSending.value = true;
      const data = {
        program_id: props.programId,
        document_type: props?.isDraft ? 'ProgramRevision' : 'Program'
      };
      const organizerDetail = organizationData.value.find(
        elem => elem.name === organizationName.value
      );
      if (organizationType.value === 'Organization') {
        data.invitee = organizerDetail.created_by;
        data.user_type = organizationType.value;
        data.orgId = organizerDetail._id;
      } else {
        data.invitee =
          inviteType.value === 'Email' ? newOrganizerEmail.value : formattedNumber.value;
        data.user_type = 'Employer';
      }
      const response = await addStackholders(data);
      if (!response.error && response.status === 200) {
        await organizerList();
        orgMsg.value = 'User added succesfully';
        isSending.value = false;
      } else {
        errorMsg.value = response.error?.description;
        isSending.value = false;
      }
    }

    const changeOrgPerm = async (action: string, id: string, indx: number) => {
      if (action === 'Remove') {
        deleteIndex.value = indx;
        try {
          isDeleting.value = true;
          const resp = await removeStackholder({
            user_id: id.toString(),
            program_id: props.programId,
            document_type: props?.isDraft ? 'ProgramRevision' : 'Program'
          });
          if (resp) {
            list.value.splice(
              list.value.findIndex(i => {
                return i._id.toString() === id.toString();
              }),
              1
            );
            isDeleting.value = false;
            orgMsg.value = resp.data.message;
          }
        } catch (err) {
          console.log(err);
          isDeleting.value = false;
        }
      }
    };

    return {
      user,
      newOrganizerEmail,
      inviteType,
      phoneNumber,
      formattedNumber,
      isPhoneValid,
      phoneValid,
      orgMsg,
      getValidation,
      isPhoneCheck,
      addOrganizer,
      manageAccess,
      isSending,
      isFetching,
      isDeleting,
      deleteIndex,
      changeOrgPerm,
      list,
      errorMsg,
      manageInput,
      organizationType,
      manageOrg,
      organizationName,
      isOrgExist,
      organizationData,
      organizationList
    };
  }
});
